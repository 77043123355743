<template>
  <div>
    <b-row>
      <!-- Calibration Items List -->
      <b-col md="9">
        <calibration-items-list />
      </b-col>
      <!-- ** Calibration Items List -->

      <!-- Calibration Certificate Informations -->
      <b-col md="3">
        <informations />
      </b-col>
      <!-- ** Calibration Certificate Informations -->
    </b-row>
  </div>
</template>

<script>
import Informations from "./informations.vue";
import { BRow, BCol } from "bootstrap-vue";
import CalibrationItemsList from "./calibrationItemsList.vue";

export default {
  components: { BRow, BCol, Informations, CalibrationItemsList },
};
</script>
