<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />
    <!-- Calibration Certification Verifying -->
    <b-card v-else :title="$t('g.calibrationInformations')">
      <!-- Instrument Name -->
      <b-col cols="12" class="mb-1">
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar rounded variant="light-primary" size="40">
              <feather-icon icon="UserIcon" size="20" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <small class="mb-0">
              {{ $t("g.insturment_name") }}
            </small>
            <h6>{{ calibrationCertificateData.insturment_name }}</h6>
          </b-media-body>
        </b-media>
      </b-col>
      <!-- ** Instrument Name -->
      <!-- Instrument Id -->
      <b-col cols="12" class="mb-1">
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar rounded variant="light-danger" size="40">
              <feather-icon icon="UserIcon" size="20" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <small class="mb-0">
              {{ $t("g.insturment_id") }}
            </small>
            <h6>{{ calibrationCertificateData.insturment_id }}</h6>
          </b-media-body>
        </b-media>
      </b-col>
      <!-- ** Instrument Id -->
      <!-- Instrument Serial Number -->
      <b-col cols="12" class="mb-1">
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar rounded variant="light-success" size="40">
              <feather-icon icon="BoxIcon" size="20" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <small class="mb-0">
              {{ $t("g.insturment_serial_number") }}
            </small>
            <h6>{{ calibrationCertificateData.insturment_serial_number }}</h6>
          </b-media-body>
        </b-media>
      </b-col>
      <!-- ** Instrument Serial Number -->
      <!-- Calibration Plan Year -->
      <b-col cols="12" class="mb-1">
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar rounded variant="light-warning" size="40">
              <feather-icon icon="ClockIcon" size="20" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <small class="mb-0">
              {{ $t("g.trainingDuration") }}
            </small>
            <h6>
              <b-badge
                class="months-table"
                v-for="(
                  item, index
                ) in calibrationCertificateData.calibration_plan_year"
                :key="index"
                variant="primary"
              >
                {{ item.name }}
              </b-badge>
            </h6>
          </b-media-body>
        </b-media>
      </b-col>
      <!-- ** Calibration Plan Year -->
    </b-card>
    <!-- ** Calibration Certification Verifying -->
  </div>
</template>

<script>
import {
  BCol,
  BCard,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BBadge,
} from "bootstrap-vue";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
export default {
  components: {
    BCol,
    BCard,
    Loading,
    Error,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BBadge,
  },

  data() {
    return {
      currentId: null,
      calibrationCertificateData: null, // all of data
      isLoading: true,
      isError: null,
    };
  },
  created() {
    this.currentId = this.$route.params.id;
    // I summoned the list of training and from there I selected the trainee through the index
    this.$http
      .get(`equipmentCalibrationPlan/${this.currentId}`)
      .then((res) => {
        
        // All Of Data
        this.calibrationCertificateData = res.data;
        this.isLoading = false;
        this.isError = false;
      })
      .catch((err) => {
        console.log(err);
        this.isError = true;
        this.isLoading = false;
      });
  },
};
</script>

<style scoped>
.months-table {
  margin: 0.2rem;
}
</style>
