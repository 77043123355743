<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />

    <div v-else>
      <b-card class="sticky-header-control">
        <!-- <div class="pr-0 pl-0 d-flex justify-content-between align-items-center"> -->
        <b-row>
          <b-col class="d-flex align-items-center" md="9">
            <h3 class="text-primary m-0 p-0">
              {{ $t("g.calibrationCertificateVerifying") }} |
            </h3>
            <b-breadcrumb :items="breadCrumbItems" />
          </b-col>
          <b-col md="3" class="justify-content-end d-flex">
            <b-button
              @click.prevent="validateForm"
              type="submit"
              variant="primary"
              >{{ $t("g.submit") }}</b-button
            >
          </b-col>
        </b-row>
        <!-- </div> -->
      </b-card>
      <b-card>
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          styleClass="vgt-table condensed"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-column" slot-scope="props">
            <!-- Column: Name -->
            <span
              v-if="props.column.label === 'element_to_be_calibrate'"
              class="text-nowrap"
            >
              {{ $t("g.element_to_be_calibrate") }}
            </span>
            <span
              v-else-if="props.column.label === 'calibration_tool_name'"
              class="text-nowrap"
            >
              {{ $t("g.calibration_tool_name") }}
            </span>
            <span
              v-else-if="props.column.label === 'next_calibration_date'"
              class="text-nowrap"
            >
              {{ $t("g.next_calibration_date") }}
            </span>
            <span
              v-else-if="props.column.label === 'supplier'"
              class="text-nowrap"
            >
              {{ $t("g.supplier") }}
            </span>
            <span
              v-else-if="props.column.label === 'maintenance_duration'"
              class="text-nowrap"
            >
              {{ $t("g.maintenance_duration") }}
            </span>
            <span
              v-else-if="props.column.label === 'instrument_situation'"
              class="text-nowrap"
            >
              {{ $t("g.instrument_situation") }}
            </span>

            <span
              v-else-if="props.column.label === 'action'"
              class="text-nowrap"
            >
              {{ $t("g.action") }}
            </span>
            <span v-else>
              {{ props.column.label }}
            </span>
          </template>

          <!-- Slot: Table Row -->

          <!-- Table Inputs For : accuracy & uncertainty -->
          <template slot="table-row" slot-scope="props">
            <!-- Accuracy -->
            <span v-if="props.column.field == 'accuracy'">
              <validation-observer
                v-if="rows"
                :ref="`accuracyForm${props.index}`"
              >
                <b-form>
                  <validation-provider
                    #default="{ errors }"
                    name="accuracy"
                    rules="required"
                  >
                    <b-form-input
                      v-model="rows[props.index].accuracy"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('g.placeholder.enterTheaccuracyHere')"
                    />
                    <small class="text-danger">{{
                      errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form>
              </validation-observer>
            </span>
            <!-- ** Accuracy -->

            <!-- Uncertainty -->
            <span v-else-if="props.column.field == 'uncertainty'">
              <validation-observer
                v-if="rows"
                :ref="`uncertaintyForm${props.index}`"
              >
                <b-form>
                  <validation-provider
                    #default="{ errors }"
                    name="uncertainty"
                    rules="required"
                  >
                    <b-form-input
                      v-model="rows[props.index].uncertainty"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('g.placeholder.enterTheuncertaintyHere')"
                    />
                    <small class="text-danger">{{
                      errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form>
              </validation-observer>
            </span>

            <!-- ** Uncertainty -->
            <!-- ** Table Inputs For : accuracy & uncertainty -->

            <!-- Clear the content of accuracy -> we replaced it with input field -->
            <span v-else-if="props.column.field === 'accuracy'">
              <span v-show="false"> {{ props.row.accuracy }} </span>
            </span>
            <!-- ****** -->

            <!-- Clear the content of accuracy -> we replaced it with input field -->
            <span v-else-if="props.column.field === 'uncertainty'">
              <span v-show="false"> {{ props.row.uncertainty }} </span>
            </span>
            <!-- ****** -->

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <!-- <b-dropdown-item>
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>{{ $t("g.addAttendance") }}</span>
              </b-dropdown-item> -->

                  <b-dropdown-item
                    :to="{ name: 'Maintenance', params: { id: props.row.id } }"
                  >
                    <feather-icon icon="SettingsIcon" class="mr-50" />
                    <span>{{ $t("g.maintenance") }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteAlert">
                    <feather-icon icon="Trash2Icon" class="mr-50" />
                    <span>{{ $t("g.delete") }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  {{ $t("g.pagelength") }}
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3', '5', '10']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap">
                  {{ $t("g.of") }} {{ props.total }}
                  {{ $t("g.pageText") }}
                </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template> </vue-good-table
      ></b-card>
      <b-card :title="$t('g.results')">
        <validation-observer ref="resultForm">
          <b-form>
            <b-row>
              <!-- Calibration Result -->
              <b-col md="6">
                <b-form-group :label="$t('g.calibrationResult')">
                  <validation-provider
                    #default="{ errors }"
                    name="calibrationResult"
                    rules="required"
                  >
                    <div class="d-flex">
                      <b-form-checkbox
                        v-model="result_data.calibration_result"
                        name="some-radio9"
                        value="pass"
                        :state="errors.length > 0 ? false : null"
                        class="custom-control-success"
                      >
                        {{ $t("g.pass") }}</b-form-checkbox
                      >
                      <b-form-checkbox
                        v-model="result_data.calibration_result"
                        name="some-radio9"
                        value="notPass"
                        :state="errors.length > 0 ? false : null"
                        class="custom-control-danger mr-1 ml-1"
                      >
                        {{ $t("g.notPass") }}</b-form-checkbox
                      >
                    </div>
                    <small class="text-danger">{{
                      errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- ** Calibration Result -->

              <!-- Result of the calibration certificate verification according to standard ISO 17025 -->
              <b-col md="6">
                <b-form-group
                  :label="$t('g.calibrationCertificateVerification')"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="calibration certificate verification"
                    rules="required"
                  >
                    <div class="d-flex">
                      <b-form-checkbox
                        v-model="
                          result_data.calibration_certificate_verification
                        "
                        name="some-radio9"
                        value="conformity"
                        :state="errors.length > 0 ? false : null"
                        class="custom-control-success"
                      >
                        {{ $t("g.conformity") }}</b-form-checkbox
                      >
                      <b-form-checkbox
                        v-model="
                          result_data.calibration_certificate_verification
                        "
                        name="some-radio9"
                        value="nonConformity"
                        :state="errors.length > 0 ? false : null"
                        class="custom-control-danger mr-1 ml-1"
                      >
                        {{ $t("g.nonConformity") }}</b-form-checkbox
                      >
                    </div>

                    <small class="text-danger">{{
                      errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- ** Result of the calibration certificate verification according to standard ISO 17025 -->
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BButton,
  BDropdownItem,
  BDropdown,
  VBTooltip,
  BForm,
  BFormCheckbox,
  BCol,
  BRow,
  BBreadcrumb,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BButton,
    Loading,
    Error,
    BDropdownItem,
    BDropdown,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormCheckbox,
    BCol,
    BRow,
    BBreadcrumb,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      log: [],
      pageLength: 10,
      dir: false,
      result_data: {
        calibration_result: null,
        calibration_certificate_verification: null,
      },

      columns: [
        {
          label: "element_to_be_calibrate",
          field: "element_to_be_calibrate",
        },
        {
          label: "calibration_tool_name",
          field: "calibration_tool_name",
        },
        {
          label: "next_calibration_date",
          field: "next_calibration_date",
        },
        {
          label: "accuracy",
          field: "accuracy",
        },
        {
          label: "uncertainty",
          field: "uncertainty",
        },

        {
          label: "action",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
      isLoading: true,
      isError: false,
      breadCrumbItems: [
        {
          text: this.$t("g.home"),
          to: { name: "Home" },
        },
        {
          text: this.$t("g.equipmentCalibrationPlan"),
          to: { name: "EquipmentCalibrationPlan" },
        },
        {
          text: this.$t("g.calibrationCertificateVerifying"),
          active: true,
        },
      ],
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    // get data from backend
    this.$http
      .get("equipmentCalibrationPlan")
      .then((res) => {
        this.rows = res.data;
        this.isLoading = false;
        this.isError = false;
      })
      .catch((err) => {
        console.log(err);
        this.isLoading = false;
        this.isError = true;
      });
  },
  methods: {
    // Sweet Alert
    deleteAlert() {
      this.$swal({
        title: this.$t("g.areYouSure?"),
        text: this.$t("g.youWontBeAbleToRevertThis"), // You won't be able to revert this!
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("g.yesDeleteIt"), // "Yes, delete it!" ,
        cancelButtonText: this.$t("g.cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$swal({
            icon: "success",
            title: this.$t("g.deleted"), //"Deleted!"
            text: this.$t("g.yourFileHasBeenDeleted"), //"Your file has been deleted."
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },

    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },

    clearForm() {
      let form = this.rows,
        i;
      for (i in form) {
        form[i].accuracy = null;
        form[i].uncertainty = null;
      }
    },

    resetValidate(allData) {
      allData.forEach((item, index) => {
        this.$refs[item].reset();
      });
    },

    validateForm() {
      let validationArray = [], // (if this array length == rows length) => valid
        allData = ["resultForm"];

      for (let i in this.rows) {
        let accuracyForm = "accuracyForm" + i,
          uncertaintyForm = "uncertaintyForm" + i;

        allData.push("accuracyForm" + i, "uncertaintyForm" + i);
      }

      if (allData.length == this.rows.length * 2 + 1) {
        allData.forEach((item, index) => {
          this.$refs[item].validate().then((success) => {
            if (success) {
              validationArray.push(success);
              if (validationArray.length == this.rows.length * 2 + 1) {
                this.submitData();
                this.clearForm();
                this.resetValidate(allData);
              }
            }
          });
        });
      }
    },

    submitData() {
      let formData = new FormData();
      // Append table form
      for (let item in this.rows) {
        formData.append(item, this.rows[item]);
        console.log(item);
      }
      // append result data form
      for (let item in this.result_data) {
        formData.append(item, this.result_data[item]);
      }

      this.$http
        .post("https://api.npoint.io/bd1ce6326b271bece1de", formData)
        .then((res) => {
          this.makeToast(
            "success",
            this.$t("g.send.successBody"),
            this.$t("g.send.successTitle")
          );
          setTimeout(() => {
            this.$router.push({ name: "EquipmentCalibrationPlan" });
          }, 500);
        })
        .catch((err) => {
          for (const key in err.response.data.errors) {
            this.makeToast(
              "danger",
              err.response.data.errors[key][0],
              this.$t("g.send.errorTitle")
            );
          }
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
